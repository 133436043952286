/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, LinkWithQuery, MapInfo, TwoColCta, LandingPageCta, ThreeColCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!LinkWithQuery) _missingMdxReference("LinkWithQuery", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!TwoColCta) _missingMdxReference("TwoColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/technology/'], ['en', 'https://www.hear.com/hearing-aids/technology/'], ['en-US', 'https://www.hear.com/hearing-aids/technology/'], ['en-IN', 'https://www.hear.com/in/hearing-aids/technology'], ['en-MY', 'https://www.hear.com/my/hearing-aids/technologies/'], ['ko-KR', 'https://www.hear.com/kr/signia-hearing-aid-technology'], ['en-CA', 'https://ca.hear.com/hearing-aids/technology/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Boasting a variety of sophisticated technologies, today’s hearing aids are truly some of the most amazing minicomputers around. While most ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/"
  }, "medical-grade hearing aids"), " now feature modern conveniences like smartphone controls, Bluetooth connectivity and rechargeability (no tiny batteries!), the technology continues to advance daily beyond what anyone could’ve imagined."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Here’s a snapshot of some of the impressive technology features that many of today’s hearing aids offer:"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "bluetooth-rechargeability--smartphone-connectivity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bluetooth-rechargeability--smartphone-connectivity",
    "aria-label": "bluetooth rechargeability  smartphone connectivity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bluetooth, Rechargeability & Smartphone Connectivity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Even though ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/technology/bluetooth/"
  }, "Bluetooth"), ", ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/technology/rechargeable/"
  }, "rechargeability"), ", & ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/technology/apps/"
  }, "smartphone connectivity"), " often come standard with many of today’s hearing aids, don’t underestimate the major impact these features have on improving everyday life."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "bluetooth-connectivity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bluetooth-connectivity",
    "aria-label": "bluetooth connectivity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bluetooth connectivity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This makes staying connected with the world around you easy. Just like the most popular wireless headphones on the market today, many hearing aids now allow you to stream your favorite TV shows, podcasts, music and phone calls directly into your ears."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "rechargeable-batteries",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rechargeable-batteries",
    "aria-label": "rechargeable batteries permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rechargeable batteries"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Having these in hearing aids are becoming increasingly more popular and even come with portable charging cases for extra security and convenience when you’re on the go. They’re also environmentally-friendly and easier on the wallet (and who doesn’t want that!). Charging devices now make it easier than ever to keep your hearing aids operating all day with no interruption — and no more having to mess with hard-to-replace tiny batteries!"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "intuitive-smartphone-app",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#intuitive-smartphone-app",
    "aria-label": "intuitive smartphone app permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Intuitive Smartphone app"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These setting controls give you the ability to easily change your hearing aid volume or program based on your environment. For example, if you’re going from your house to a crowded restaurant, you’ll probably want to adjust the levels so you’re comfortable. Now you can do it quickly and easily from your iPhone or Android smartphone without ever having to touch your hearing aids."), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "speech-clarity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#speech-clarity",
    "aria-label": "speech clarity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Speech Clarity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/technology/speech-clarity/"
  }, "Improved speech clarity"), " has always been the top desired feature of hearing aid wearers. And rightfully so. Hearing loss is actually an issue with speech clarity, NOT volume, and older hearing aids just weren’t very effective. But times have changed. Today’s medical-grade hearing aids have drastically improved speech understanding like never before. Advanced features incorporate narrow directionality, echo adjustments, and spatial configurators to deliver the highest level of speech clarity in the most challenging environments."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "background-noise-reduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#background-noise-reduction",
    "aria-label": "background noise reduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Background Noise Reduction"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Background noise can make it more difficult to follow a conversation and understand every word. That’s why the latest ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/technology/background-noise-reduction/"
  }, "hearing aids have background noise reduction technology"), " that can identify background noise and filter it out. This also includes annoying feedback and whistling that used to accompany old hearing aids. With these enhancements, you’ll enjoy a more natural hearing experience and reduced listening effort, leaving you with more energy throughout your day."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "directional--open-360-sound-with-movement",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#directional--open-360-sound-with-movement",
    "aria-label": "directional  open 360 sound with movement permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Directional & Open 360° Sound with Movement"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Clearly hearing the sounds all around us is critical to everyday life. Think about when you’re driving in traffic or crossing the street. Not realizing an emergency vehicle is coming up behind you or turning in your direction could mean the difference between life and death. Modern hearing aids now have acoustic and motion sensors that help to process sounds no matter what environment you’re in. These new sensors help the brain understand and process sound directionality so you clearly hear in the most natural way possible."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "automatic-environment-detection--adjustments",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#automatic-environment-detection--adjustments",
    "aria-label": "automatic environment detection  adjustments permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Automatic Environment Detection & Adjustments"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your hearing is influenced by your environment. Modern hearing aids scan your surroundings over 10,000 times a second and take your movement into consideration. They automatically adjusting their programs to deliver an optimal, natural sound experience based on any environment, even while you’re walking (without you having to adjust the settings)."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "own-voice-processing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#own-voice-processing",
    "aria-label": "own voice processing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Own Voice Processing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Modern hearing aids provide the most natural experience by ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/technology/own-voice-processing/"
  }, "processing your own voice instantly"), " and independently from all other voices and sounds. Whether you’re a new or long-time hearing aid wearer, you’ll finally be able to hear your own voice in the most natural way possible."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "binaural-processing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#binaural-processing",
    "aria-label": "binaural processing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Binaural Processing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You might think you only need one hearing aid for the “bad ear.” But you actually need two hearing aids to get the best performance. Here’s why: Your ears and brain work together. Binaural processing enables two hearing aids to communicate wirelessly and mimic your brain’s natural ability to process information from both ears. A single hearing aid simply cannot provide the same level of hearing quality that two devices can."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-tech-to-fit-any-lifestyle",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-tech-to-fit-any-lifestyle",
    "aria-label": "hearing aid tech to fit any lifestyle permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aid Tech To Fit Any Lifestyle"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We sell a large variety of cutting-edge, medical-grade hearing aids to help people with mild to severe hearing loss. By partnering with the world’s leading manufacturers, we ensure our customers have access to only the best modern hearing aids on the market today. Here are just a few of our favorites."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "horizon-ix-hearing-aids-by-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-ix-hearing-aids-by-hearcom",
    "aria-label": "horizon ix hearing aids by hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon IX Hearing Aids by hear.com"), "\n", React.createElement(TwoColCta, {
    imgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids.png",
    copy1: "Horizon Go IX",
    url1: "/hearing-aids/horizon/go-ix/",
    imgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/mini-ix-hearing-aids.png",
    copy2: "Horizon Mini IX",
    url2: "/hearing-aids/horizon/mini-ix/"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Big features in a tiny, ", React.createElement(LinkWithQuery, {
    to: "/news/press-releases/hear-com-wins-two-big-innovation-awards/"
  }, "award-winning design"), ". This cool new ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/horizon/"
  }, "Horizon IX hearing aids line by hear.com"), " is bursting with industry-leading technology and features that no other hearing aid on the market offers. Top features geared toward younger, active people include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Bluetooth connectivity for streaming of music, phone calls and TV shows"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Wirelessly rechargeable (lasts up to 19 hours!); includes a portable charging case with Qi technology"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Speech Focus: An extra boost of speech clarity for the most challenging situations (exclusive to Horizon)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Relax Mode: Streams ocean sounds into your ears when you’re needing some R&R (exclusive to Horizon)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "IP68-rated, which means its resistant to moisture, sweat and dirt"), "\n"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-silk-x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-silk-x",
    "aria-label": "signia silk x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia Silk X"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Nearly invisible, tiny design that disappears inside the ear. This tiny device packs a big technology punch, using a unique algorithm that is clinically proven to deliver better-than-normal hearing."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Crystal-clear speech clarity"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Smartphone connectivity and controls"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "No one will know you’re wearing it"), "\n"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "starkey-livio-ai",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#starkey-livio-ai",
    "aria-label": "starkey livio ai permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Starkey Livio AI"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Exceptional hearing that goes far beyond better hearing. If you’re looking for something that goes beyond improving your hearing,  Livio AI uses artificial intelligence to offer health benefits, language translation and more."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Bluetooth and smartphone connectivity"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Rechargeable batteries that can last over 20 hours"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Brain & body activity tracking and health monitoring, even fall detection"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Translates languages"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "test-drive-this-superior-hearing-aid-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#test-drive-this-superior-hearing-aid-technology",
    "aria-label": "test drive this superior hearing aid technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Test Drive This Superior Hearing Aid Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Click the button below to sign up for our exclusive hearing aid trial to test drive the latest hearing aid technology. You’ll have 45 days to hear firsthand the positive difference it can make in your life."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
